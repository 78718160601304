import { Vue, Options } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import { Tournaments } from '@/services/TournamentsDataService'
import SysTournamentRow, { SysTournamentMatchSchedule, SysTournamentMatchSettlement, tournamentMatchScheduleTeamStatusFriendlyType, SysTournamentRules } from '@/types/SysTournament'
import SysSaeson from '@/types/SysSaeson'
import SeasonsDataService from '@/services/SeasonsDataService'
import CommonFunctions from '@/components/Utility/Common'
import BaseFunctions from '@/components/Utility/Base'
import TeamsDataService from '@/services/TeamsDataService'
import SysTeam from '@/types/SysTeam'
import SysMatchRequestDateChange, { SysMatchRequestFriendlyType } from '@/types/SysMatchDateChange'
import MatchDateChangeDataService from '@/services/MatchDateChangeDataService'
import Vue3Html2pdf from 'vue3-html2pdf'
import SysMember from '@/types/SysMember'
import MembersDataService from '@/services/MembersDataService'

@Options({
  components: {
    Vue3Html2pdf
  }
})
export default class matchSchedule extends Vue {
  $Message: any
  private todaysDate = new Date().toISOString().split('T')[0]
  private matchSchedule: SysTournamentMatchSchedule[] = []
  private seriesString = ''
  private seasonString = ''
  private matchParam = this.parseURL()
  private seriesInfo = {} as SysTournamentRow
  private seasonInfo = {} as SysSaeson
  private teams: SysTeam[] = []
  private teamFilterValue: { teamName: string | undefined, teamId: string | undefined } = { teamName: 'Alle hold', teamId: '0' }
  private teamFilterOptions: { teamName: string, teamId: number }[] = [{ teamName: 'Alle hold', teamId: 0 }]
  private teamExtraParameterString = ''
  private matchScoresParameters = ''
  private matchResults: SysTournamentMatchSettlement[] = []
  private requesteeValueString = 'Vælg hold'
  private requesteeOptionsString: string[] = ['Hjemme holdet', 'Udeholdet']
  private requestModal = false
  private requestMatchId = 0
  private newMatchDate = ''
  pendingRequests: SysMatchRequestDateChange[] = []
  private rankingsModal = false
  private teamRankingsArray: { teamId: number, teamName: string, points: number, matches: number, won: number, lost: number, tied: number, wonScore: number, lostScore: number, matchNumbers: number[] }[] = []
  selectedMatch = sessionStorage.getItem('SelectedMatch')

  @Watch('teamFilterValue')
  onTeamFilterChange () : void {
    if (!this.teamFilterValue.teamId || this.teamFilterValue.teamId === '0') {
      this.teamExtraParameterString = ''
    } else {
      // this.teamExtraParameterString = `&_where[_or][0][kampprogram_hjemmehold.id]=${this.teamFilterValue.teamId}&_where[_or][1][kampprogram_udehold.id]=${this.teamFilterValue.teamId}`
      this.teamExtraParameterString = '&_where[_or][0][kampprogram_hjemmehold.id]=' + this.teamFilterValue.teamId + '&_where[_or][1][kampprogram_udehold.id]=' + this.teamFilterValue.teamId
    }

    // console.log('onTeamFilterChange', this.teamFilterValue)
    // console.log('extraParamterString ', this.teamExtraParameterString)
    this.retrieveMatchSchedule()
  }

  beforeMount (): void {
    if (typeof this.$route.query?.teamName !== 'undefined' && typeof this.$route.query?.teamId !== 'undefined') {
      this.teamFilterValue = { teamName: this.$route.query.teamName?.toString(), teamId: this.$route.query.teamId?.toString() }
      // console.log('beforeMount ', this.teamFilterValue, this.teamExtraParameterString)
    }
  }

  beforeUpdate (): void {
    this.$router.push({ name: 'MatchSchedule', query: { teamName: this.teamFilterValue.teamName, teamId: this.teamFilterValue.teamId } })
  }

  @Watch('requestModal')
  onRequestModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.requesteeValueString = 'Vælg hold'
      this.requestMatchId = 0
      this.newMatchDate = ''
    }
  }

  public async getRanking () : Promise<void> {
    this.rankingsModal = true
    this.teamRankingsArray = []
    const retrieveRulesPromise = Tournaments.TournamentRulesDataService.getAll()
    const retrieveTeamsPromise = TeamsDataService.getAll('', null, `saeson_id=${this.matchParam.seasonId.toString()}&raekke_id=${this.matchParam.seriesId}`)
    const retrieveMatchSchedulePromise = Tournaments.TournamentMatchSchedulesDataService.getAll('', null, `saeson_id=${this.matchParam.seasonId}&raekke_id=${this.matchParam.seriesId}`)

    await Promise.all([retrieveRulesPromise, retrieveTeamsPromise, retrieveMatchSchedulePromise])
      .then(async (response) => {
        let matchScores: SysTournamentMatchSettlement[] = []
        let rules = response[0].data as SysTournamentRules[]
        let isFinishingMatchSeries = false
        const teams = response[1].data as SysTeam[]
        const matchSchedule = response[2].data as SysTournamentMatchSchedule[]
        // console.log('[getRanking()] series = ' + this.seriesString.toLowerCase())

        if (this.seriesString.toLowerCase().includes('slutspil')) {
          isFinishingMatchSeries = true
        }

        // Filter the rules to find the correct ones
        if (this.seriesString.toLowerCase().includes('damepairs')) {
          // console.log('[getRanking()] Damepairs rules filtering is used.')
          rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('damepairs'))
        } else if (this.seriesString.toLowerCase().includes('junior')) {
          // console.log('[getRanking()] Junior rules filtering is used.')
          rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('juniorrækken'))
        } else if (this.seriesString.toLowerCase().includes('oldboys 35') || this.seriesString.toLowerCase().includes('oldboys +35')) {
          // console.log('[getRanking()] Oldboys 35+ rules filtering is used.')
          rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('oldboys 35'))
        } else if (this.seriesString.toLowerCase().includes('oldboys 50') || this.seriesString.toLowerCase().includes('oldboys +50')) {
          // console.log('[getRanking()] Oldboys 50+ rules filtering is used.')
          rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('oldboys 50'))
          // } else if (this.seriesString.toLowerCase().includes('hverdag region') && (parseInt(this.seriesString) === 3 || parseInt(this.seriesString) === 4 || parseInt(this.seriesString) === 5)) {
        } else if (this.seriesString.toLowerCase().includes('hverdag region')) {
          const posInString = this.seriesString.toLowerCase().trim().search('hverdag region')
          const endPartOfString = this.seriesString.toLowerCase().trim().substring(posInString + 14).trim()

          if (endPartOfString.startsWith('1')) {
            // console.log('[getRanking()] Hverdagsrække 1 rules filtering is used.')
            rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 1')) >= 0))
          } else if (endPartOfString.startsWith('2')) {
            // console.log('[getRanking()] Hverdagsrække 2 rules filtering is used.')
            rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 2')) >= 0))
          } else if (endPartOfString.startsWith('3')) {
            // console.log('[getRanking()] Hverdagsrække 3 rules filtering is used.')
            rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 3')) >= 0))
          } else if (endPartOfString.startsWith('4')) {
            // console.log('[getRanking()] Hverdagsrække 4 rules filtering is used.')
            rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 4')) >= 0))
          } else if (endPartOfString.startsWith('5')) {
            // console.log('[getRanking()] Hverdagsrække 5 rules filtering is used.')
            rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hverdagsrækken') && (el.regions.findIndex(x => x.region_navn.toLowerCase().trim().startsWith('region 5')) >= 0))
          } else {
            // console.log('[getRanking()] Hovedturnering rules filtering is used (default).')
            rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hovedturnering'))
          }
        } else if (this.seriesString.toLowerCase().includes('paradart')) {
          console.log('[getRanking()] paradart rules filtering is used (default).')
          rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('paradart'))
        } else {
          // console.log('[getRanking()] Hovedturnering rules filtering is used (default).')
          rules = rules.filter(el => el.turneringskategori_id.turneringskategori_navn.toLowerCase().includes('hovedturnering'))
        }
        // console.log('[getRanking()] rules = ' + JSON.stringify(rules))

        // Initialize the ranking array
        for (const team of teams) {
          this.teamRankingsArray.push({
            teamId: Number(team.id),
            teamName: team.hold_holdnavn,
            points: (isFinishingMatchSeries && team.hold_startpoint !== undefined && team.hold_startpoint != null ? Number(team.hold_startpoint) : 0),
            matches: 0,
            won: 0,
            lost: 0,
            tied: 0,
            wonScore: 0,
            lostScore: 0,
            matchNumbers: []
          })
        }
        let matchScoreParameter = ''
        for (const item of matchSchedule) {
          matchScoreParameter += '&kampprogram_id.id=' + Number(item.id).toString()
        }
        matchScoreParameter = matchScoreParameter.slice(1)

        try {
          matchScores = (await Tournaments.TournamentMatchSettlementDataService.getAll('', null, matchScoreParameter)).data
        } catch (err) {
          console.log(err)
        }

        // Constructing the rankings, for each match check that there is some results, then assign scores to the home team and guest team
        for (const match of matchSchedule) {
          const homeTeamId = Number(match.kampprogram_hjemmehold.id)
          const guestTeamId = Number(match.kampprogram_udehold.id)
          let homeScore = 0
          let guestScore = 0
          const scoreIndex = matchScores.findIndex(el => Number(el.kampprogram_id.id) === Number(match.id))

          if (scoreIndex !== -1 && match.kampgodkendt_id !== null && match.kampgodkendt_id.kampgodkendt_godkendt) {
            homeScore = matchScores[scoreIndex].kampafvikling_resultat_hjem === null ? 0 : matchScores[scoreIndex].kampafvikling_resultat_hjem
            guestScore = matchScores[scoreIndex].kampafvikling_resultat_ude === null ? 0 : matchScores[scoreIndex].kampafvikling_resultat_ude
            const homeScoreString = homeScore.toString() + '-' + guestScore.toString()
            const guestScoreString = guestScore.toString() + '-' + homeScore.toString()

            const homeIndex = this.teamRankingsArray.findIndex(el => el.teamId === Number(homeTeamId))
            const rulesNineToZeroIndex = rules.findIndex(el => el.turneringsregler_resultat === '9-0')

            if (homeIndex !== -1) {
              const wonMatch = homeScore > guestScore
              // Based on the homeScoreString of the homeTeam, find how many points should be awarded
              const rulesIndex = rules.findIndex(el => el.turneringsregler_resultat === homeScoreString)
              // console.log('[getRanking()] homeTeamId = ' + Number(homeTeamId).toString())
              // console.log('[getRanking()] homeScoreString = ' + homeScoreString)
              // console.log('[getRanking()] adding points = ' + (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point)))
              this.teamRankingsArray[homeIndex].matchNumbers.push(Number(match.id))
              this.teamRankingsArray[homeIndex].points += (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point))
              this.teamRankingsArray[homeIndex].matches++
              this.teamRankingsArray[homeIndex].won += wonMatch ? 1 : 0
              this.teamRankingsArray[homeIndex].lost += (!wonMatch && (homeScore !== guestScore) ? 1 : 0)
              this.teamRankingsArray[homeIndex].tied += homeScore === guestScore ? 1 : 0
              this.teamRankingsArray[homeIndex].wonScore += homeScore
              this.teamRankingsArray[homeIndex].lostScore += guestScore

              // Handle points reduction for non-appearance situations of the home team in the matches.
              if (match.kampprogram_afb_udb === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelsehjem) {
                if (this.seriesString.toLowerCase().includes('hverdag region') && match.raekke_id.raekke_antalspillere === 3) {
                  this.teamRankingsArray[homeIndex].points += -(rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4)
                  console.log('Match Id ' + Number(match.id) + ' POINTS reduction with ' + (rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4) + ' of home team.')
                } else {
                  this.teamRankingsArray[homeIndex].points += -0
                }
              }

              // If the team has been withdrawn from the tournament season, then set the value of points to zero.
              if (match.kampprogram_hjemmehold.hold_status !== null && match.kampprogram_hjemmehold.hold_status === false) {
                this.teamRankingsArray[homeIndex].points = 0
              }
            }

            const guestIndex = this.teamRankingsArray.findIndex(el => el.teamId === Number(guestTeamId))

            if (guestIndex !== -1) {
              const wonMatch = guestScore > homeScore
              // Same for the guestTeam
              const rulesIndex = rules.findIndex(el => el.turneringsregler_resultat === guestScoreString)
              // console.log('[getRanking()] guestTeamId = ' + Number(guestTeamId).toString())
              // console.log('[getRanking()] guestScoreString = ' + guestScoreString)
              // console.log('[getRanking()] adding points = ' + (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point)))
              this.teamRankingsArray[guestIndex].matchNumbers.push(Number(match.id))
              this.teamRankingsArray[guestIndex].points += (rulesIndex === -1 ? 0 : (isFinishingMatchSeries ? rules[rulesIndex].turneringsregler_point * 2 : rules[rulesIndex].turneringsregler_point))
              this.teamRankingsArray[guestIndex].matches++
              this.teamRankingsArray[guestIndex].won += wonMatch ? 1 : 0
              this.teamRankingsArray[guestIndex].lost += (!wonMatch && (homeScore !== guestScore) ? 1 : 0)
              this.teamRankingsArray[guestIndex].tied += guestScore === homeScore ? 1 : 0
              this.teamRankingsArray[guestIndex].wonScore += guestScore
              this.teamRankingsArray[guestIndex].lostScore += homeScore

              // Handle points reduction for non-appearance situations of the away team in the matches.
              if (match.kampprogram_afb_udb === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelseud) {
                if (this.seriesString.toLowerCase().includes('hverdag region') && match.raekke_id.raekke_antalspillere === 3) {
                  this.teamRankingsArray[guestIndex].points += -(rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4)
                  console.log('Match Id ' + Number(match.id) + ' POINTS reduction with ' + (rulesNineToZeroIndex >= 0 ? rules[rulesNineToZeroIndex].turneringsregler_point : 4) + ' of away team.')
                } else {
                  this.teamRankingsArray[guestIndex].points += -0
                }
              }

              // If the team has been withdrawn from the tournament season, then set the value of points to zero.
              if (match.kampprogram_udehold.hold_status !== null && match.kampprogram_udehold.hold_status === false) {
                this.teamRankingsArray[guestIndex].points = 0
              }
            }
          }
        }

        console.log('[getRanking()] Before sorting teamRankingsArray = ' + JSON.stringify(this.teamRankingsArray))

        // After the ranking array have been constructed, sort it
        this.teamRankingsArray.sort(function compare (a, b) {
          let mutualMatchesNumbers: number[] = []

          if (a.matchNumbers.length >= 1 && b.matchNumbers.length >= 1) {
            mutualMatchesNumbers = a.matchNumbers.filter(el => b.matchNumbers.includes(el))
          }

          if (a.points === b.points && mutualMatchesNumbers.length >= 1) {
            let teamAWon = 0
            let teamBWon = 0
            let teamAScore = 0
            let teamBScore = 0

            if (mutualMatchesNumbers.length > 0) {
              const teamAId = a.teamId
              // const teamBId = b.teamId
              // const mutualMatches2 = matchScores.filter((el) => {
              //   return mutualMatchesNumbers.some((f) => {
              //     return f === Number(el.kampprogram_id.id)
              //   })
              // })

              const mutualMatches = matchScores.filter((el) => {
                for (const matchNumber of mutualMatchesNumbers) {
                  if (matchNumber === Number(el.kampprogram_id.id)) {
                    return true
                  }
                }

                return false
              })

              console.log('[getRanking()] mutualMatchesNumbers = ' + JSON.stringify(mutualMatchesNumbers))

              for (const match of mutualMatches) {
                if (match.kampprogram_id.kampprogram_hjemmehold === teamAId) {
                  // Situation where A is the home team.
                  // teamAScore += (match.kampafvikling_resultat_hjem - match.kampafvikling_resultat_ude)
                  // teamBScore += (match.kampafvikling_resultat_ude - match.kampafvikling_resultat_hjem)
                  teamAScore += match.kampafvikling_resultat_hjem
                  teamBScore += match.kampafvikling_resultat_ude

                  if (match.kampafvikling_resultat_hjem > match.kampafvikling_resultat_ude) {
                    teamAWon++
                  }
                  if (match.kampafvikling_resultat_hjem < match.kampafvikling_resultat_ude) {
                    teamBWon++
                  }
                } else {
                  // Situation where B is the home team.
                  // teamAScore += (match.kampafvikling_resultat_ude - match.kampafvikling_resultat_hjem)
                  // teamBScore += (match.kampafvikling_resultat_hjem - match.kampafvikling_resultat_ude)
                  teamAScore += match.kampafvikling_resultat_ude
                  teamBScore += match.kampafvikling_resultat_hjem

                  if (match.kampafvikling_resultat_ude > match.kampafvikling_resultat_hjem) {
                    teamAWon++
                  }
                  if (match.kampafvikling_resultat_ude < match.kampafvikling_resultat_hjem) {
                    teamBWon++
                  }
                }
              }

              console.log('Team Ids: ' + a.teamId + ' / ' + b.teamId + ' the calculated score is ' + teamAScore + ' / ' + teamBScore)

              if (teamAWon === teamBWon) {
                if (teamAScore === teamBScore) {
                  const aWonLostRatio = a.wonScore - a.lostScore
                  const bWonLostRatio = b.wonScore - b.lostScore

                  if (bWonLostRatio - aWonLostRatio === 0) {
                    console.log('Sort return 0 - teams with same number of won matches and same score. Team Ids: ' + a.teamId + ' / ' + b.teamId)
                  }

                  return bWonLostRatio - aWonLostRatio
                }

                if (teamBScore - teamAScore === 0) {
                  console.log('Sort return 0 - teams with same number of won matches. Team Ids: ' + a.teamId + ' / ' + b.teamId)
                }

                return teamBScore - teamAScore
              }
              return teamBWon - teamAWon
            } else {
              if (a.points === b.points) {
                if (teamAScore === teamBScore) {
                  const aWonLostRatio = a.wonScore - a.lostScore
                  const bWonLostRatio = b.wonScore - b.lostScore

                  if (bWonLostRatio - aWonLostRatio === 0) {
                    console.log('Sort return 0 - teams with same points and score values. Team Ids: ' + a.teamId + ' / ' + b.teamId)
                  }

                  return bWonLostRatio - aWonLostRatio
                }

                if (teamBScore - teamAScore === 0) {
                  console.log('Sort return 0 - teams with same points values (2). Team Ids: ' + a.teamId + ' / ' + b.teamId)
                }

                return teamBScore - teamAScore
              }
            }
          }

          if (b.points - a.points === 0) {
            console.log('Sort return 0 - teams with same points values (0). Team Ids: ' + a.teamId + ' / ' + b.teamId)
          }

          return b.points - a.points
        })

        console.log('[getRanking()] After sorting teamRankingsArray = ' + JSON.stringify(this.teamRankingsArray))

        // Find the total match scores differences of each mutual matches between each team in the match schedule.
        const listOfProcessedPoints: number[] = []
        const listOfMutualMatchesTotalMatchScoreDiff: { team1Id: number, team2Id: number, totalScoreDiff: number, points: number }[] = []

        for (const teamRankingsElA of this.teamRankingsArray) {
          if (listOfProcessedPoints.indexOf(teamRankingsElA.points)) {
            listOfProcessedPoints.push(teamRankingsElA.points)

            const teamRankingsWithSamePoint = this.teamRankingsArray.filter(x => x.points === teamRankingsElA.points)

            if (teamRankingsWithSamePoint.length >= 2) {
              // Calculate the total score difference of alle matches for each team combination in the same point level.
              for (const teamRankingsElB of teamRankingsWithSamePoint) {
                const mutualMatchesNumbers = teamRankingsElA.matchNumbers.filter(el => teamRankingsElB.matchNumbers.includes(el))

                if (teamRankingsElA.teamId !== teamRankingsElB.teamId && mutualMatchesNumbers.length > 0) {
                  // const mutualMatches = matchScores.filter((el) => {
                  //   return mutualMatchesNumbers.some((f) => {
                  //     return f === Number(el.kampprogram_id.id)
                  //   })
                  // })

                  const mutualMatches = matchScores.filter((el) => {
                    for (const matchNumber of mutualMatchesNumbers) {
                      if (matchNumber === Number(el.kampprogram_id.id)) {
                        return true
                      }
                    }

                    return false
                  })

                  let teamAScore = 0
                  let teamBScore = 0

                  for (const match of mutualMatches) {
                    if (match.kampprogram_id.kampprogram_hjemmehold === teamRankingsElA.teamId) {
                      // Situation where A is the home team.
                      teamAScore += match.kampafvikling_resultat_hjem
                      teamBScore += match.kampafvikling_resultat_ude
                    } else {
                      teamAScore += match.kampafvikling_resultat_ude
                      teamBScore += match.kampafvikling_resultat_hjem
                    }
                  }

                  listOfMutualMatchesTotalMatchScoreDiff.push({ team1Id: teamRankingsElA.teamId, team2Id: teamRankingsElB.teamId, totalScoreDiff: teamAScore - teamBScore, points: teamRankingsElA.points })
                }
              }
            }
          }
        }

        listOfMutualMatchesTotalMatchScoreDiff.sort(function compare (a, b) {
          return a.totalScoreDiff - b.totalScoreDiff
        })

        console.log('[getRanking()] listOfMutualMatchesTotalMatchScoreDiff = ' + JSON.stringify(listOfMutualMatchesTotalMatchScoreDiff))

        // Besed upon the previously found total match scores differences of mutual matches, attempt to make any relevant adjustment to the index positions in the team rankings array.
        for (let arrIndex1 = 0; arrIndex1 < this.teamRankingsArray.length; arrIndex1++) {
          const listPlacementAdjustmentsToBeDone: { srcTeamId: number, destTeamId: number }[] = []

          for (const teamRankingsElA of this.teamRankingsArray) {
            const indexArrayOffsetOfteamRankingsElA = this.teamRankingsArray.findIndex(x => x.teamId === teamRankingsElA.teamId)

            if (indexArrayOffsetOfteamRankingsElA >= 0 && listOfProcessedPoints.indexOf(teamRankingsElA.points)) {
              listOfProcessedPoints.push(teamRankingsElA.points)

              const teamRankingsWithSamePoint = this.teamRankingsArray.filter(x => x.points === teamRankingsElA.points)

              if (teamRankingsWithSamePoint.length >= 2) {
                let minTotalMatchScoreDiff = 0

                for (const teamRankingsElB of teamRankingsWithSamePoint) {
                  const indexArrayOffsetOfteamRankingsElB = this.teamRankingsArray.findIndex(x => x.teamId === teamRankingsElB.teamId)

                  if (indexArrayOffsetOfteamRankingsElB >= 0 && indexArrayOffsetOfteamRankingsElB > indexArrayOffsetOfteamRankingsElA && teamRankingsElA.teamId !== teamRankingsElB.teamId) {
                    const indexOflistOfMutualMatchesTotalMatchScoreDiff = listOfMutualMatchesTotalMatchScoreDiff.findIndex(x => x.team1Id === teamRankingsElA.teamId && x.team2Id === teamRankingsElB.teamId)
                    minTotalMatchScoreDiff = listOfMutualMatchesTotalMatchScoreDiff.filter(x => (x.team1Id === teamRankingsElA.teamId || x.team2Id === teamRankingsElA.teamId) && x.team1Id !== teamRankingsElB.teamId && x.team2Id !== teamRankingsElB.teamId && x.points === teamRankingsElA.points).reduce((prev, current) => (prev && prev.totalScoreDiff < current.totalScoreDiff) ? prev : current).totalScoreDiff
                    console.log('[getRanking()] Compare the scores difference of mutual matches of the following teams: teamIds = [ ' + teamRankingsElA.teamId + ' / ' + teamRankingsElB.teamId + ' ] ; indexOflistOfMutualMatchesTotalMatchScoreDiff = ' + indexOflistOfMutualMatchesTotalMatchScoreDiff + ' ; minTotalMatchScoreDiff = ' + minTotalMatchScoreDiff)

                    if (indexOflistOfMutualMatchesTotalMatchScoreDiff >= 0) {
                      if (listOfMutualMatchesTotalMatchScoreDiff[indexOflistOfMutualMatchesTotalMatchScoreDiff].totalScoreDiff < minTotalMatchScoreDiff) {
                        // Record the needed information for the team item index position change to relevant object array list.
                        console.log('[getRanking()] Compared the scores difference - the score difference of team with Id = ' + teamRankingsElB.teamId + ' causes the team to trumph the initial placement in relations to team with Id = ' + teamRankingsElA.teamId + ' .')
                        listPlacementAdjustmentsToBeDone.push({ srcTeamId: teamRankingsElB.teamId, destTeamId: teamRankingsElA.teamId })
                      }
                    }
                  }
                }
              }
            }
          }

          // const changeItemPosition = (arr: any[], init: number, target: number) => { [arr[init], arr[target]] = [arr[target], arr[init]]; return arr }
          console.log('[getRanking()] listPlacementAdjustmentsToBeDone = ' + JSON.stringify(listPlacementAdjustmentsToBeDone))

          // Perform the actual index position adjustments in the team rankings array, that has previously been recorded.
          for (const listPlacementAdjustmentsItem of listPlacementAdjustmentsToBeDone) {
            const indexOfSrcTeamId = this.teamRankingsArray.findIndex(x => x.teamId === listPlacementAdjustmentsItem.srcTeamId)
            const indexOfDestTeamId = this.teamRankingsArray.findIndex(x => x.teamId === listPlacementAdjustmentsItem.destTeamId)

            if (indexOfSrcTeamId >= 0 && indexOfDestTeamId >= 0) {
              // changeItemPosition(this.teamRankingsArray, indexOfSrcTeamId, indexOfDestTeamId)
              // this.teamRankingsArray.copyWithin(indexOfSrcTeamId, indexOfSrcTeamId + 1, indexOfDestTeamId + 1)[indexOfDestTeamId] = this.teamRankingsArray[indexOfSrcTeamId]
              // this.teamRankingsArray = BaseFunctions.moveItemInArray(this.teamRankingsArray, indexOfSrcTeamId, indexOfDestTeamId)
              this.teamRankingsArray = BaseFunctions.moveItemInArray2(this.teamRankingsArray, indexOfSrcTeamId, indexOfDestTeamId)
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public haveRequestPending (matchId: number) : boolean {
    const index = this.pendingRequests.findIndex(el => Number(el.kampprogram_id.id) === Number(matchId))
    console.log(index)

    if (index !== -1) {
      return true
    }

    return false
  }

  public retrieveRequests () : void {
    MatchDateChangeDataService.getAll('', null, 'kampflytning_anmodning=afventer')
      .then((response) => {
        this.pendingRequests = response.data
        // console.log(this.pendingRequests)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public createNewMatchRequest () : void {
    if (this.requesteeValueString === 'Vælg hold') {
      return
    }

    const index = this.matchSchedule.findIndex(el => el.id === this.requestMatchId)

    if (index === -1) {
      return
    }

    const match = this.matchSchedule[index]
    const createRequest = {
      kampflytning_nydato: new Date(this.newMatchDate).toISOString(),
      kampprogram_id: this.requestMatchId,
      kampflytning_status: true,
      kampflytning_anmodning: SysMatchRequestFriendlyType.Afventer,
      anmoder_hold_id: this.requesteeValueString === 'Hjemme holdet' ? match.kampprogram_hjemmehold.id : match.kampprogram_udehold.id,
      modtager_hold_id: this.requesteeValueString === 'Hjemme holdet' ? match.kampprogram_udehold.id : match.kampprogram_hjemmehold.id
    }

    MatchDateChangeDataService.create(createRequest)
      .then((response) => {
        console.log(response.data)
        this.requestModal = false
        this.retrieveRequests()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public getEnumComment (protestEnum: tournamentMatchScheduleTeamStatusFriendlyType | null) : string {
    let retVal = ''

    if (protestEnum === null) {
      retVal = ''
    } else if (protestEnum === tournamentMatchScheduleTeamStatusFriendlyType.Afbudhjem) {
      retVal = 'Afbud fra hjemmeholdet'
    } else if (protestEnum === tournamentMatchScheduleTeamStatusFriendlyType.Afbudud) {
      retVal = 'Afbud fra udeholdet'
    } else if (protestEnum === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelsehjem) {
      retVal = 'Udeblivelse fra hjemmeholdet'
    } else if (protestEnum === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelseud) {
      retVal = 'Udeblivelse fra udeholdet'
    } else {
      retVal = ''
    }

    return retVal
  }

  public async makeRequest (matchId: number, homeTeamId: number, guestTeamId: number) : Promise<void> {
    let members: SysMember[] = []
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      this.$Message.danger({ text: 'Fejl: Noget gik galt' })

      return
    }

    try {
      members = (await MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)).data
    } catch (err) {
      console.log(err)
    }

    if (members.length !== 1) {
      this.$Message.danger({ text: 'Fejl: Noget gik galt' })

      return
    }

    if (homeTeamId >= 1 && guestTeamId >= 1 && (Number(members[0].klubber_id.id) === homeTeamId || Number(members[0].klubber_id.id) === guestTeamId)) {
      this.requesteeValueString = (Number(members[0].klubber_id.id) === homeTeamId || Number(members[0].klubber_id.id) === guestTeamId ? this.requesteeOptionsString[(Number(members[0].klubber_id.id) === homeTeamId ? 0 : 1)] : 'Vælg hold')
      this.requestModal = true
      this.requestMatchId = matchId
    }
  }

  public getScores (matchId: number) : string {
    let retVal = '0 — 0'

    if (this.matchResults.length > 0) {
      const index = this.matchResults.findIndex(el => Number(el.kampprogram_id.id) === Number(matchId))

      if (index !== -1) {
        const matchResult = this.matchResults[index]
        const udScore = (matchResult.kampafvikling_resultat_ude === null ? '0' : matchResult.kampafvikling_resultat_ude.toString())
        const hjScore = (matchResult.kampafvikling_resultat_hjem === null ? '0' : matchResult.kampafvikling_resultat_hjem.toString())
        // retVal = (matchResult.kampafvikling_resultat_hjem === null ? '0' : matchResult.kampafvikling_resultat_hjem.toString()) + ' — ' + (matchResult.kampafvikling_resultat_ude === null ? '0' : matchResult.kampafvikling_resultat_ude.toString())
        retVal = new Array(Math.max(hjScore.length, udScore.length) - hjScore.length + 1).join(' ') + hjScore + ' — ' + udScore + new Array(Math.max(hjScore.length, udScore.length) - udScore.length + 1).join(' ')
      }
    }

    return retVal
  }

  public retrieveMatchSchedule () : void {
    // console.log(this.matchParam)
    const param = 'saeson_id=' + this.matchParam.seasonId + '&raekke_id=' + this.matchParam.seriesId + this.teamExtraParameterString
    // console.log(param)
    Tournaments.TournamentMatchSchedulesDataService.getAll('kampprogram_dato:asc,kampprogram_kampnr:asc', null, param)
      .then((response) => {
        this.matchSchedule = response.data
        // console.log(this.matchSchedule)

        for (const item of this.matchSchedule) {
          this.matchScoresParameters += '&kampprogram_id.id=' + Number(item.id).toString()
        }

        // this.matchScoresParameters = this.matchScoresParameters.slice(1)
        // console.log(this.matchScoresParameters)

        Tournaments.TournamentMatchSettlementDataService.getAll('', null, this.matchScoresParameters)
          .then((response) => {
            this.matchResults = response.data
            // console.log(this.matchResults)
          })
          .catch((err) => {
            console.error(err)
          })
        // console.log(this.matchSchedule)
      })
      .catch((err) => {
        console.error(err)
      })

    SeasonsDataService.get(this.matchParam.seasonId.toString())
      .then((response) => {
        this.seasonInfo = response.data
        this.seasonString = this.seasonInfo.saeson_navn
      })
      .catch((err) => {
        console.error(err)
      })

    Tournaments.TournamentRowsDataService.get(this.matchParam.seriesId.toString())
      .then((response) => {
        this.seriesInfo = response.data
        this.seriesString = this.seriesInfo.raekke_navn
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveTeams () : void {
    // this.teamFilterOptions = [{ teamName: 'Alle hold', teamId: 0 }]
    this.teamFilterOptions = []

    TeamsDataService.getAll('', null, `saeson_id=${this.matchParam.seasonId}&raekke_id=${this.matchParam.seriesId}`)
      .then((response) => {
        this.teams = response.data

        for (const item of this.teams) {
          this.teamFilterOptions.push({ teamName: item.hold_holdnavn, teamId: Number(item.id) })
        }

        this.teamFilterOptions.sort((a, b) => a.teamName.localeCompare(b.teamName))
        this.teamFilterOptions.unshift({ teamName: 'Alle hold', teamId: 0 })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public parseURL () : { seasonId: number, seriesId: number } {
    const retVal = { seasonId: 0, seriesId: 0 }
    const route = useRoute()
    const ids = route.params.tournamentSlug.toString().split('_')[0]

    if (ids !== undefined) {
      retVal.seasonId = Number(ids.split('-')[0])
      retVal.seriesId = Number(ids.split('-')[1])
    }
    return retVal
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClock (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 3) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public retrieveDateFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // this.todaysDate = response.data.split('T')[0]
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public downloadPdf () : void {
    const pdfApi = this.$refs.html2Pdf as any
    pdfApi.generatePdf()
  }

  public newMatchDatePossible (matchCurrentDate: string, matchNewDate: string | null) : boolean {
    if (matchNewDate === null) {
      matchNewDate = matchCurrentDate
    }

    if (this.todaysDate <= matchNewDate) {
      return true
    }

    return false
  }

  public mayChangeMatchDate () : boolean {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.role !== undefined && userDataObject.role !== null && (userDataObject.usrgroup.id === 2 || userDataObject.usrgroup.id === 2 || userDataObject.usrgroup.id === 3 || userDataObject.usrgroup.id === 4)) {
        return true
      }
    }

    return false
  }

  public setSelectedMatch (match: any) : void {
    window.sessionStorage.setItem('SelectedMatch', match.id)
  }

  public scrollIntoSelectedMatch () : void {
    setTimeout(() => {
      const desktopElement = document.getElementById('selectedMatchDesktop')
      const mobileElement = document.getElementById('selectedMatchMobile')
      desktopElement && desktopElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      mobileElement && mobileElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 3000)
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.role !== undefined && userDataObject.role !== null && (userDataObject.usrgroup.id === 2 || userDataObject.usrgroup.id === 2 || userDataObject.usrgroup.id === 3 || userDataObject.usrgroup.id === 4)) {
        this.retrieveRequests()
      }
    }

    // console.log('[mounted()] Team input data: ', this.teamFilterValue, this.teamExtraParameterString)
    this.retrieveMatchSchedule()
    this.retrieveTeams()
    this.retrieveDateFromServer()
    this.scrollIntoSelectedMatch()
  }
}
